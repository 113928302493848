import { ENV } from "@metasearch-io/shared/env";

export const SENTRY_DSN = "https://b5af8ca52bf034cc4f6f92fc46308637@sentry.kaito.ai/16";

export const getTracesSamplerRate = (samplingContext) => {
  if (ENV.stage === "dev") {
    return 1.0; // 100% sampling for development
  }
  return 0.1; // 10% sampling rate for production
};
